import { KenticoImage, KenticoChoice } from "@utils/KontentTypes";
import { convertKontentColorToHex } from "@utils/Helpers";

interface KiwiVipVisibleProps{
    general_page_content__kiwivip_signup:{
        value: KenticoChoice[]
    }
    general_page_content__kiwivip_signup_version:{
        value: KenticoChoice[]
    }
}


export const mapToKiwiVIPVisible = (data: KiwiVipVisibleProps) =>{
    const kiwiVipSignup = data.general_page_content__kiwivip_signup.value[0].codename; //codename visible or hide
    const kiwiVipSignupVersion = data.general_page_content__kiwivip_signup_version.value[0].codename; //codename standard, large or none
    return {
        visible: kiwiVipSignup === 'visible',
        version: kiwiVipSignupVersion
    }
}

interface KiwiVipSignupProps{
    title:{
        value:string
    }
    image:{
        value: Array<KenticoImage>
    }
    button__text:{
        value: string
    }
    button__button_color_theme:{
        value: KenticoChoice[]
    }
    button__button_type?:{
        value: KenticoChoice[]
    }
    button__url_slug:{
        value:string
    }
}

export const mapToKiwiVIP = (data:KiwiVipSignupProps) =>{
    const {title,image,button__text,button__button_color_theme,button__button_type,button__url_slug} = data;
    const imageAsset = image === undefined ? {url: '',description: ''} : image.value[0];
    if(button__button_type === undefined){
        throw new TypeError("KiwiVIP Mapper Error: button type is undefined.")
    }
    return {
        title: title.value,
        image: {
            url: imageAsset.url,
            description: imageAsset.description
        },
        button: {
            type: button__button_type.value[0].codename as string,
            theme: convertKontentColorToHex(button__button_color_theme.value[0].codename),
            text: button__text.value,
            url: button__url_slug.value
        }
    }
}